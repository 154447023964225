/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-10-02T14:37:48.975Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.3.10401-1572891';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
